import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Dynamic imports
const Form = dynamic(() => import('../Form'));
const SocialMenu = dynamic(() => import('../Navigation/SocialMenu/SocialMenu'));
const TechnicalMenu = dynamic(() => import('../Navigation/TechnicalMenu/TechnicalMenu'));

const Footer = ({ path }) => {
  const s = require('./Footer.module.scss');
  const year = new Date().getFullYear();

  return (
    <div className={s.Footer}>
      <div className={s.Cell}>
        <SocialMenu />
        <Form border={false} />
        <TechnicalMenu path={path} />
        <p className={s.CopyRights}>Vodacom {year}. All rights reserved</p>
      </div>
    </div>
  );
};

Footer.propTypes = {
  path: PropTypes.string,
};

export default Footer;
